import { createApp } from "petite-vue";
import SimpleLightbox from "simple-lightbox";
import Swiper from "swiper";
import { Navigation, Keyboard, Autoplay } from "swiper/modules";

import.meta.glob(["@images/**"]);
import.meta.env.DEV && import("@styles/main.sass");

//----------------------------------------------------Header
interface Header {
  opened: boolean;
  handleOpenedHeader(state: boolean): void;
}

const headerEl = document.querySelector("[data-header]"),
  navEl = document.querySelector(".HeaderNavigation"),
  headerScope: Header = {
    opened: false,
    handleOpenedHeader(state) {
      document.body.classList.toggle("u-hiddenOverflow", state);
      if (navEl) {
        navEl.classList.toggle("is-opened", state);
      }
      headerScope.opened = state;

      const burgerButton = document.querySelector(".HeaderBurger");
      if (burgerButton) {
        if (state) {
          burgerButton.classList.add("is-opened");
        } else {
          burgerButton.classList.remove("is-opened");
        }
      }
    },
  };

headerEl && createApp(headerScope).mount(headerEl);

function normalizeUrl(url) {
  // Remove trailing slash, except when the URL is the homepage
  return url.endsWith("/") && url.length > 1 ? url.slice(0, -1) : url;
}

function updateActiveLink() {
  const menuLinks = document.querySelectorAll(".HeaderNavigation a");
  const currentUrl = normalizeUrl(window.location.href);
  const bodyClass = document.body.className;

  let isServicePage =
    currentUrl.includes("/produkty-a-sluzby") ||
    bodyClass.includes("template-service");
  let isTestimonialPage =
    currentUrl.includes("/reference") ||
    bodyClass.includes("single-testimonial");
  let isSinglePostPage =
    currentUrl.includes("/aktuality") || bodyClass.includes("single-post");
  let isCareerPage = currentUrl.includes("/kariera");

  menuLinks.forEach((link) => {
    if (link instanceof HTMLAnchorElement) {
      link.classList.remove("active");

      const linkUrl = normalizeUrl(link.href);

      if (linkUrl === currentUrl) {
        link.classList.add("active");
      } else if (currentUrl.startsWith(linkUrl + "/")) {
        link.classList.add("active");
      } else if (isServicePage && linkUrl.includes("/produkty-a-sluzby")) {
        link.classList.add("active");
      } else if (isTestimonialPage && linkUrl.includes("/reference")) {
        link.classList.add("active");
      } else if (isSinglePostPage && linkUrl.includes("/aktuality")) {
        link.classList.add("active");
      } else if (isCareerPage && linkUrl.includes("/kariera")) {
        link.classList.add("active");
      } else {
        // Check if the current URL is a subpage of the link URL
        const linkUrlParts = linkUrl.split('/');
        const currentUrlParts = currentUrl.split('/');
        if (currentUrlParts.length > linkUrlParts.length && currentUrl.startsWith(linkUrl)) {
          link.classList.add("active");
        }
      }
    }
  });
  menuLinks.forEach((link) => {
    if (link.classList.contains("active")) {
      let parent = link.closest("li").parentElement.closest("li");
      while (parent) {
        parent.querySelector("a").classList.add("active");
        parent = parent.parentElement.closest("li");
      }
    }
  });
}

updateActiveLink();

//--------------------------------------------------------------------datacount
document.addEventListener("DOMContentLoaded", () => {
  const animationDuration = 2400;
  const frameDuration = 1000 / 60;
  const totalFrames = Math.round(animationDuration / frameDuration);
  const easeOutQuad = (t: number) => t * (2 - t);

  const animateCountUp = (el: HTMLElement) => {
    if (el.classList.contains("is-counted")) return;

    el.classList.add("is-counted");
    const countTo = parseInt(el.getAttribute("data-count") || "0", 10);
    let frame = 0;

    const counter = setInterval(() => {
      frame++;
      const progress = easeOutQuad(frame / totalFrames);
      const currentCount = Math.round(countTo * progress);

      if (
        parseInt(el.textContent?.replace(/\D/g, "") || "0", 10) !== currentCount
      )
        el.textContent = currentCount.toLocaleString();

      if (frame === totalFrames) clearInterval(counter);
    }, frameDuration);
  };

  const isElementInViewport = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    const inViewport =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    return inViewport;
  };

  const runCountUp = () => {
    document
      .querySelectorAll(".HomeMap-stat-number")
      .forEach((el: HTMLElement) => {
        if (isElementInViewport(el)) {
          animateCountUp(el);
        }
      });
  };

  runCountUp();
  window.addEventListener("scroll", runCountUp);
  window.addEventListener("resize", runCountUp);
});

//----------------------------------------------------Lightbox
document.addEventListener("DOMContentLoaded", () => {
  // Initialize lightbox for general gallery
  const galleryElement = document.querySelector(".wp-block-gallery");
  if (galleryElement) {
    new SimpleLightbox({
      elements: ".wp-block-gallery .wp-block-image > a",
    });
  }

  // Initialize lightbox for Swiper gallery
  const swiperGalleryElement = document.querySelector(".swiper");
  if (swiperGalleryElement) {
    new SimpleLightbox({
      elements: ".swiper-wrapper .gallery-item > a",
    });
  }

  //-------------------Lightbox for HP Reference
  const referenceGallery = document.getElementById("swiper-wrapper");
  if(referenceGallery) {
    new SimpleLightbox({
      elements: "#swiper-wrapper .Card > a"
    })
  }
});

//------------------------------------------------Swiper
(() => {
  new Swiper("[data-testimonial-gallery]", {
    cssMode: true,
    modules: [Navigation, Keyboard],
    slidesPerView: 3,
    slidesPerGroup: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
})();

document.addEventListener("DOMContentLoaded", function() {
  const fileBlocks = document.querySelectorAll(".wp-block-file");

  fileBlocks.forEach(block => {
    const element = block as HTMLElement;
    if (block.querySelector(".wp-block-file__embed")) {
      element.style.width = "100%";
    } else {
      element.style.width = "fit-content"; 
    }
  });
});


document.addEventListener('DOMContentLoaded', function () {
  const checkboxes = document.querySelectorAll('.FormCheckbox input[type="checkbox"]');

  checkboxes.forEach(function (checkbox) {
    updateOpacity(checkbox);

    checkbox.addEventListener('change', function () {
      updateOpacity(checkbox);
    });
  });

  function updateOpacity(checkbox) {
    if (checkbox.checked) {
      checkbox.closest('.FormCheckbox').classList.add('is-checked');
    } else {
      checkbox.closest('.FormCheckbox').classList.remove('is-checked');
    }
  }
});

//--------------------------------------------Swiper-reference
(() => {
  const swiperLogoContainer = document.getElementById(
      "swiper-reference"
  ) as HTMLElement;

  new Swiper(swiperLogoContainer, {

    modules: [Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
      delay: 4000,
    },
    loop: true,
    slidesPerGroup: 1,
    speed: 1000,
    grabCursor: true,
    breakpoints: {
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,

      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,

      },
      1000: {
        slidesPerView: 4,
        slidesPerGroup: 4,

      },
    },
  });
})();





